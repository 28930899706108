import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import './css/TeleviseurDetailsStyle.css';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, ImageList, Typography } from '@mui/material';
import TextFieldComponent from '../components/forms/TextField';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ButtonField from '../components/buttons/ButtonField';
import UploadField from '../components/buttons/UploadField';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgVideo from 'lightgallery/plugins/video';import lgRotate from 'lightgallery/plugins/rotate';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import LinkOffIcon from '@mui/icons-material/LinkOff';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const TVDetails = () => {
    const notifyFailed = (message) => toast.info(message);
    const notifySuccess = (message) => toast.success(message);
    const [TVData, setTVData] = useState({});
    const { user } = useAuthContext();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [IMGsData, setIMGsData] = useState([]);
    const navigate = useNavigate();
    const [selectedAccessory, setSelectedAccessory] = useState([]);
    const [arraySelectedAccessory, setArraySelectedAccessory] = useState([]);
    const [Accessoryoptions, setAccessoryoptions] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDeleteIMGDialog, setopenDeleteIMGDialog] = useState(false);
    const [openLinkAccessoryDialog, setOpenLinkAccessoryDialog] = useState(false);
    const [CurrentIMGID, setCurrentIMGID] = useState('');
    const [AccessoryTVData, setAccessoryTVData] = useState([]);
    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleClickOpenLinkAccessoryDialog = () => {
        setOpenLinkAccessoryDialog(true);
    };
    const handleClickOpenDeleteIMGDialog = (id) => {
        setCurrentIMGID(id);
        setopenDeleteIMGDialog(true);
    };
    const handleClose = () => {
        setCurrentIMGID("");
        setSelectedAccessory("");
        setArraySelectedAccessory([]);
        setOpenDeleteDialog(false);
        setopenDeleteIMGDialog(false);
        setOpenLinkAccessoryDialog(false);
    };
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('files', file); // Append each file individually
        });        
        formData.append('TeleviseurID', id);
        handleUpdloadImages(formData);
    };
    const handleSelectAccessoryChange = (e) => {
        const selectedValue = e.target.value;
    
        if (selectedValue !== "") {
            const selectedOption = Accessoryoptions.find(option => option.nom === selectedValue);
    
            if (selectedOption && !arraySelectedAccessory.includes(selectedValue)) {
                setArraySelectedAccessory(prevArray => [...prevArray, selectedValue]);
                setSelectedAccessory(prevArray => [...prevArray, selectedOption.id]);
            }
        }
    };
    
    const fetchTVIMGData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_BASE}/image/TV/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setIMGsData(data);
            } else {
                console.error("Error receiving TV images data:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching TV images data:", error);
        } finally {
            setLoading(true);
        }
    };
    const fetchAccessoryTVData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_BASE}/acceTeleviseur/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setAccessoryTVData(data);
            } else {
                console.error("Error receiving accessory by TV data:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching accessory by TV  data:", error);
        }
    };
    useEffect(() => {
        const fetchTVData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_BASE}/televiseur/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTVData(data);
                    fetchTVIMGData();
                } else {
                    console.error("Error receiving TV data:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching TV data:", error);
            } finally {
                setLoading(true);
            }
        };
        const fetchAccessoryData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_BASE}/accessoire`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setAccessoryoptions(data);
                } else {
                    console.error("Error receiving accessory data:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching accessory data:", error);
            }
        };
        fetchTVData();
        fetchAccessoryData();
        fetchAccessoryTVData();
    }, [user?.token, id, CurrentIMGID]);

    const handleDeleteSubmit = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_URL_BASE+`/televiseur/${id}`,
              {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200 || response.status === 201) {
              notifySuccess(response.data.message);
              GoBack();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error deleting TV: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error deleting TV:", error.message);
            }
        }
    };
    const handleDeleteIMGSubmit = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_URL_BASE+`/image/delete/${CurrentIMGID}`,
              {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200 || response.status === 201) {
                setIMGsData(prevData => prevData.filter(item => item.id !== CurrentIMGID));
                notifySuccess(response.data.message);
                handleClose();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error deleting images: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error deleting images:", error.message);
            }
        }
        setCurrentIMGID("");
    };
    const handleUpdloadImages = async (formData) => {
        try {
            const response = await axios.post(process.env.REACT_APP_URL_BASE+`/image/upload`,
                formData
                ,
                {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200 || response.status === 201) {
                notifySuccess(response.data.message);
                fetchTVIMGData();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error uploading images: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error uploading images:", error.message);
            }
        }
    };
    const handleLinkAccessorySubmit = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_URL_BASE+`/acceTeleviseur/link`,
                {
                    Televiseur: id,
                    Accessoire: selectedAccessory
                }
                ,
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200 || response.status === 201) {
                fetchAccessoryTVData();
                notifySuccess(response.data.message);
                handleClose();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error uploading images: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error uploading images:", error.message);
            }
        }
    };
    const handleUnlinkaccessory = async (ID) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_URL_BASE+`/acceTeleviseur/${ID}`,
              {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200 || response.status === 201) {
                setAccessoryTVData(prevData => prevData.filter(item => item.id !== ID));
                notifySuccess(response.data.message);
            } else {
                notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
                // Request was made but no response was received
                console.error("Error deleting accessory by TV: No response received", error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Error deleting accessory by TV:", error.message);
            }
        }
    }
    const GoBack = () => {
        navigate(-1)
    }
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);

    // const progressRef = useRef(() => {});
    // useEffect(() => {
    //     progressRef.current = () => {
    //     if (progress > 100) {
    //         setProgress(0);
    //         setBuffer(10);
    //     } else {
    //         const diff = Math.random() * 10;
    //         const diff2 = Math.random() * 10;
    //         setProgress(progress + diff);
    //         setBuffer(progress + diff + diff2);
    //     }
    //     };
    // });
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //     progressRef.current();
    //     }, 500);

    //     return () => {
    //     clearInterval(timer);
    //     };
    // }, []);

    if (!loading || !TVData.modele) {
        return (
            <div className="CircularProgress-app">
                <div className="CircularProgress-container">
                    <CircularProgress className="CircularProgress" />
                </div>
                <h1>Préparation des composants de la page...</h1>
            </div>
        );
    }

    return (
        <div className="TVDetails-container">
            <div className="TVDetails-content">
                <div className="TVDetails-content-navbar">
                    <div className="TVDetails-content-navbar-title">
                        <ArrowBackIosIcon onClick={GoBack} style={{cursor:'pointer'}}/>
                        <h1>Details du televiseur</h1>
                    </div>
                    <ButtonField 
                        text="Linker des accessoires"
                        onClick={handleClickOpenLinkAccessoryDialog}
                        color="#DA171B"
                        backgroundColor="#fff"
                        fontSize="16px"
                        icon='link'
                    />
                    <ButtonField 
                        text="Supprimer"
                        onClick={handleClickOpenDeleteDialog}
                        color="#DA171B"
                        backgroundColor="#fff"
                        fontSize="16px"
                        icon='delete'
                    />
                </div>
                <div className="TVDetails-content-navbar-trainingtop-bar">
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                    </Box>
                    <h1>En cours d'entraînement...</h1>
                </div>
                <div className="TVDetails-content-form">
                    <div className="TVDetails-img-content-Statut-bar">
                        <Box sx={{ position: 'relative', display: 'inline-flex', width:"100%" }}>
                            <Box
                                sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" color="text.secondary">
                                {`${progress}%`}
                                </Typography>
                            </Box>
                            <Stack spacing={2} sx={{ width:"100%" }}>
                                <h1>Statut d'entraînement:</h1>
                                <BorderLinearProgress variant="determinate" value={progress} />
                            </Stack>
                        </Box>                
                    </div>
                    <h1>Details:</h1>
                    <TextFieldComponent
                        type="text"
                        label="Marque"
                        DefaultValue={TVData.marque}
                        minLength={0}
                        maxLength={100}
                        obligatory={false}
                        color="#333"
                        readOnly={true}
                        initialHelperText='Marque indéfinie'
                    />
                    <TextFieldComponent
                        type="text"
                        label="Modele"
                        DefaultValue={TVData.modele}
                        minLength={0}
                        maxLength={100}
                        obligatory={false}
                        color="#333"
                        readOnly={true}
                        initialHelperText='Modele indéfinie'
                    />
                    <TextFieldComponent
                        type="text"
                        label="Categorie"
                        DefaultValue={TVData.categorie}
                        minLength={0}
                        maxLength={100}
                        obligatory={false}
                        color="#333"
                        readOnly={true}
                        initialHelperText='Categorie indéfinie'
                    />
                    <TextFieldComponent
                        type="text"
                        label="Taille"
                        DefaultValue={TVData.taille}
                        minLength={0}
                        maxLength={100}
                        obligatory={false}
                        color="#333"
                        readOnly={true}
                        initialHelperText='Taille indéfinie'
                    />
                    <TextFieldComponent
                        type="text"
                        label="Resolution"
                        DefaultValue={TVData.resolution}
                        minLength={0}
                        maxLength={100}
                        obligatory={false}
                        color="#333"
                        readOnly={true}
                        initialHelperText='Resolution indéfinie'
                    />
                </div>
            </div>
            <div className="TVDetails-img-content">
                {AccessoryTVData.length > 0 && 
                    <>
                        <div className="TVDetails-img-content-title-bar">
                            <h1>Accessoire:</h1>
                        </div>
                        <div className="TVDetails-img-content-accessorry">
                            {AccessoryTVData.map((item) => (
                                <div className="TVDetails-img-content-accessory-item">
                                    <h1>- {item.nom}</h1>
                                    <LinkOffIcon 
                                        onClick={() => handleUnlinkaccessory(item.id)}
                                        style={{cursor:'pointer'}}
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                }
                <div className="TVDetails-img-content-title-bar">
                    <h1>Images:</h1>
                    {IMGsData.length > 0 && <UploadField onChange={handleFileChange}/>}
                </div>
                {IMGsData.length > 0 ? (
                    <ImageList variant="masonry" cols={4} gap={10}>
                        <LightGallery
                            speed={1000}
                            plugins={[
                                lgAutoplay,
                                lgRotate,
                                lgThumbnail,
                                lgVideo,
                                lgZoom,
                            ]}
                        >
                            {IMGsData.map((item) => (
                                <a key={item.img} className="ImageListItem" href={`${process.env.REACT_APP_URL_BASE}/files/${item.chemin}?w=248&fit=crop&auto=format`}>
                                    <ClearRoundedIcon className="ImageListItem-DeleteIcon" onClick={()=>handleClickOpenDeleteIMGDialog(item.id)}/>
                                    <img
                                        srcSet={`${process.env.REACT_APP_URL_BASE}/files/${item.chemin}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${process.env.REACT_APP_URL_BASE}/files/${item.chemin}?w=248&fit=crop&auto=format`}
                                        alt={item.chemin}
                                        loading="lazy"
                                    />
                                </a>
                            ))}
                        </LightGallery>
                    </ImageList>
                ) : (
                    <div className="dropzone-file-container">
                        <label htmlFor="dropzone-file" className="dropzone-file-content">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg
                                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">Click to upload</span> or drag and drop
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                            </div>
                            <input id="dropzone-file" multiple onChange={handleFileChange}  type="file" className="hidden" />
                        </label>
                    </div>
                )}
            </div>
            <Dialog
                open={openDeleteDialog}
                onClose={false}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Suppression du televiseur</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Cette suppression sera appliquée directement après la confirmation.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleDeleteSubmit}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteIMGDialog}
                onClose={false}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Suppression d'une image</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Cette suppression sera appliquée directement après la confirmation.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleDeleteIMGSubmit}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openLinkAccessoryDialog}
                onClose={false}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>linker des accessoires</DialogTitle>
                <DialogContent>
                    <div className='TVDetails-input-select-field-container'>
                        <label className='TVDetails-input-select-field-label'>
                            Accessoire* :
                        </label>
                        <select
                            className='TVDetails-input-select-field-form'
                            onChange={handleSelectAccessoryChange}
                        >
                            <option value="" >
                                Selectionne un accessoire
                            </option>
                            {Accessoryoptions.map((option, index) => (
                            <option key={index} value={option.nom}>
                                {option.nom}
                            </option>
                            ))}
                        </select>
                    </div>
                    
                    <DialogContentText
                        style={{marginTop:"20px"}}
                    >
                        <Typography
                            variant="body3"
                            color="#202020"
                            style={{
                                fontSize:"1rem",
                                fontWeight:"500",
                            }}
                        >
                            Les accessoires selectionner :
                        </Typography>
                        <DialogContentText
                            marginTop={'10px'}
                        >
                            {arraySelectedAccessory?.map((item, index) => (
                                <DialogContentText
                                    display={'flex'}
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                >
                                    <Typography
                                        variant="body2"
                                        color="#202020"
                                        style={{
                                            fontSize:"1rem",
                                            fontWeight:"500"
                                        }}
                                    >
                                        -{` ${item}`}
                                    </Typography>
                                    <LinkOffIcon 
                                        onClick={()=>{
                                            setArraySelectedAccessory(prevArray => prevArray.filter((_, i) => i !== index));
                                            setSelectedAccessory(prevArray => prevArray.filter((_, i) => i !== index));
                                        }}
                                        style={{cursor:'pointer'}}
                                    />
                                </DialogContentText>
                            ))}
                        </DialogContentText>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleLinkAccessorySubmit}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </div>
    );
};

export default TVDetails;
