import { useEffect, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { CircularProgress } from '@mui/material';
import DataTable from '../components/tables/DataTable';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CreateCategoryDialog from '../components/Dialogs/CreateCategoryDialog';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CategoriePage = () => {
    const notifyFailed = (message) => toast.info(message);
    const notifySuccess = (message) => toast.success(message);
    const [CategorieDATA, setCategorieDATA] = useState([]);
    const { user } = useAuthContext();
    const [Loading, setLoading] = useState(false);
    // handle open dialog
    const [open, setOpen] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    // handle close dialog
    const handleClose = () => {
        setNom('');
        setCurrentID(null);
        setOpen(false);
        setOpenCreate(false);
        setOpenDeleteDialog(false);
    };
    //handle open dialog
    const handleClickOpen = (id) => {
        setCurrentID(id);
        setOpen(true);
    };
    // handle create dialog
    const handleClickCreateOpen = () => {
        setOpenCreate(true);
    };
    //handle delete dialog
    const handleClickDeleteOpen = (id) => {
        setCurrentID(id);
        setOpenDeleteDialog(true);
    }; 
    // handle refetch data
    const [refetchData, setrefetchData] = useState(false);
    const handleRefetchDataChange = () => {
        setrefetchData(!refetchData);
    }
    const [Nom, setNom] = useState('');
    const [currentID , setCurrentID] = useState(null);
    // handle update
    const handleOnUpdate = async () => {
        try {
            const response = await axios.patch(process.env.REACT_APP_URL_BASE+`/categorie/${currentID}`,
                {
                    Nom: Nom
                }
                ,
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200) {
                handleRefetchDataChange();
                notifySuccess(response.data.message);
                handleClose();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error updating category: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error updating category:", error.message);
            }
        }
    };
    // handle delete
    const handleOnDelete = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_URL_BASE+`/categorie/${currentID}`,
              {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200) {
                setCategorieDATA(prevData => prevData.filter(item => item.id !== currentID));
                handleRefetchDataChange();
                notifySuccess(response.data.message);
                handleClose();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error deleting accessory: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error deleting accessory:", error.message);
            }
        } 
    };
    //fetch data
    useEffect(() => { 
        const fetchCategorieDATA = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_URL_BASE+`/categorie`,
                {
                    method: "GET",
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                    },
                }
                );
        
                if (response.ok) {
                    const data = await response.json();
                    setCategorieDATA(data);
                } else {
                    console.error("Error receiving categorie data:", response.message);
                }
            } catch (error) {
                console.error("Error fetching categorie data:", error);
            } finally {
                setLoading(true);
            }
        };
        fetchCategorieDATA();
    },[user?.token, refetchData]);
    //columns for the table
    const columns = [
        {
            name: "nom",
            label: "Nom",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            label: " ",
            name: "id",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div>
                            <button style={{backgroundColor: '#1988ff'}} onClick={() => handleClickOpen(value) }>
                                Edit
                            </button>
                            <button style={{backgroundColor: '#DA171B'}} onClick={() => handleClickDeleteOpen(value) }>
                                Delete
                            </button>
                        </div>
                    )
                }
            }
        }
    ]; 

    if (!Loading) {
        return (
          <div className="CircularProgress-app">
            <div className="CircularProgress-container">
              <CircularProgress className='CircularProgress' />
            </div>  
            <h1>Préparation des composants de la page...</h1>
          </div>
        );
    }
    
    return (
        <div className="pages-container">
            <DataTable 
                name={"categories"} 
                data={CategorieDATA.length <= 0 
                ? [] : 
                CategorieDATA
                } 
                columns={columns} 
                handleClickOpen={handleClickCreateOpen}
            />
            <CreateCategoryDialog  open={openCreate} handleClose={handleClose} user={user} refetchData={handleRefetchDataChange}/>
            <Dialog
                open={open}
                onClose={false}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Modification</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Cette modification sera appliquée directement après confirmation.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="nom"
                    name="nom"
                    label="Entre le nouveau nom de cet catégorie"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setNom(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleOnUpdate}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteDialog}
                onClose={false}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Suppression</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Cette suppression sera appliquée directement après confirmation.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleOnDelete}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </div>
    );
}
export default CategoriePage;