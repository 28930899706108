import { useEffect, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import './css/TeleviseurDetailsStyle.css';
import { CircularProgress } from '@mui/material';

const ProfilPage = () => {
    const [ProfilDATA, setProfilDATA] = useState({});
    const { user } = useAuthContext();
    const [Loading, setLoading] = useState(false);

    useEffect(() => { 
        const fetchProfilDATA = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_URL_BASE+`/user`,
                {
                    method: "GET",
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                    },
                }
                );
        
                if (response.ok) {
                    const data = await response.json();
                    setProfilDATA(data);
                } else {
                    console.error("Error receiving user data:", response.message);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(true);
            }
        };
        fetchProfilDATA();
    },[user?.token]);

    if (!Loading) {
        return (
          <div className="CircularProgress-app">
            <div className="CircularProgress-container">
              <CircularProgress className='CircularProgress' />
            </div>  
            <h1>Préparation des composants de la page...</h1>
          </div>
        );
    }

    return (
        <div className="pages-container">
            <h1>Profil page</h1>
        </div>
    );
}
export default ProfilPage;