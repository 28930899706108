import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, List } from '@mui/material';
import TextFieldComponent from '../forms/TextField';
import SelectFieldComponent from '../forms/SelectField';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#DA171B',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor:'#383838',
    color: '#FFF',
  },
  padding: '8px 16px',
  borderRadius: '4px',
  textTransform: 'none',
}));

export default function Tvdialog(props) {
  const notifyFailed = (message) => toast.info(message);
  const notifySuccess = (message) => toast.success(message);
  const [Modele, setModele] = useState('');
  const handleModeleChange = (event) => {
    setModele(event.target.value);
  };
  const [Marque, setMarque] = useState('');
  const handleMarqueChange = (event) => {
    setMarque(event.target.value);
  };
  const [Categorie, setCategorie] = useState('');
  const handleCategorieChange = (event) => {
    setCategorie(event.target.value);
  };
  const [Taille, setTaille] = useState('');
  const handleTailleChange = (event) => {
    setTaille(event.target.value);
  };
  const [Resolution, setResolution] = useState('');
  const handleResolutionChange = (event) => {
    setResolution(event.target.value);
  };
  const [CategorieList, setCategorieList] = useState([]);
  const [CategorieLoading, setCategorieLoading] = useState(false);

  useEffect(() => { 
    const fetchCategorieData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_URL_BASE+`/categorie`,
          {
              method: "GET",
              headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${props.user?.token}`,
              },
          }
          );
  
          if (response.ok) {
              const data = await response.json();
              setCategorieList(data);
              setCategorieLoading(true);
          } else {
              setCategorieLoading(true);
              console.error("Error receiving categorie data:", response.message);
          }
      } catch (error) {
          console.error("Error fetching categorie data:", error);
      }
    };
    fetchCategorieData();
  },[props.user?.token]);

  const handleSave = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_URL_BASE+`/televiseur/create`, 
        { 
          Modele: Modele,
          Marque: Marque,
          Categorie: Categorie,
          Taille: Taille,
          Resolution: Resolution,
        }, 
        {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${props.user?.token}`,
          }
        }
      );
      if (response.status === 200) {
        notifySuccess(response.data.message);
        props.handleClose();
      } else {
        notifyFailed(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        notifyFailed(error.response.data.message);
      } else if (error.request) {
        // Request was made but no response was received
        console.error("Error creating TV: No response received", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error creating TV:", error.message);
      }
    }
    props.refetchData();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          sx: {
            backgroundColor: '#DA171B',
          },
        }}
      >
        <AppBar  color='error' >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1,  }} variant="h6" component="div" >
              Ajouter un Televiseur
            </Typography>
            <StyledButton autoFocus color="inherit" onClick={handleSave}>
              sauvgarder
            </StyledButton>
          </Toolbar>
        </AppBar>
        <List>
        <DialogContent sx={{ marginTop: '40px' }}> 
            {!CategorieLoading ?
              <Box display="flex" flexDirection="column" alignItems="centre" mt={2} sx={{ 
                width: '100%',
                height: '80vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '20px',
                gap: '20px',
              }}>
                <CircularProgress 
                  sx={{
                    color: (theme) => (theme.palette.mode === 'light' ? '#fff' : 'fff'),
                    animationDuration: '2000ms',
                  }}
                />
                <h1>Préparation du formulaire...</h1>
              </Box>
              : 
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2} sx={{ width: '100%' }}>
                <TextFieldComponent 
                    type="text" 
                    label="Marque" 
                    initialHelperText="Entrer la marque de votre produite" 
                    minLength={0} 
                    maxLength={100}
                    onChange={handleMarqueChange}
                    obligatory={true}
                    color='#fff'
                />
                <TextFieldComponent 
                    type="text" 
                    label="Modele" 
                    initialHelperText="Entrer la taille de votre produit" 
                    minLength={0} 
                    maxLength={100} 
                    onChange={handleModeleChange}
                    obligatory={true}
                    color='#fff'
                />
                <SelectFieldComponent 
                    label="Categorie" 
                    initialHelperText="Selectionner votre Categorie" 
                    minLength={0} 
                    maxLength={100} 
                    onChange={handleCategorieChange}
                    obligatory={true}
                    options={CategorieList}
                    optionName='nom'
                />
                <TextFieldComponent 
                    type="text" 
                    label="Taille" 
                    initialHelperText="Entrer la taille de votre produit" 
                    minLength={0} 
                    maxLength={100} 
                    onChange={handleTailleChange}
                    obligatory={true}
                    color='#fff'
                />
                <TextFieldComponent 
                    type="text" 
                    label="Resolution" 
                    initialHelperText="Entrer la resolution de votre produit" 
                    minLength={0} 
                    maxLength={100} 
                    onChange={handleResolutionChange}
                    obligatory={true}
                    color='#fff'
                />
              </Box>
            }
        </DialogContent>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
