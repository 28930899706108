import { useEffect, useState } from 'react';
import './css/HomeStyle.css';
import { useAuthContext } from '../hooks/useAuthContext';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DataTable from '../components/tables/DataTable';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CreateAccessoryDialog from '../components/Dialogs/CreateAccessoryDialog';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccessoryDATAPage = () => {
    const notifyFailed = (message) => toast.info(message);
    const notifySuccess = (message) => toast.success(message);
    const [AccessoryDATA, setAccessoryDATA] = useState([]);
    const { user } = useAuthContext();
    const [Loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const handleClose = () => {
        setNom('');
        setQuantite('');
        setCurrentID(null);
        setOpen(false);
        setOpenCreate(false);
    };
    const handleClickOpen = (id) => {
        setCurrentID(id);
        setOpen(true);
    };
    const handleClickCreateOpen = () => {
        setOpenCreate(true);
    };
    const [refetchData, setrefetchData] = useState(false);
    const handleRefetchDataChange = () => {
        setrefetchData(!refetchData);
    }
    const [Nom, setNom] = useState('');
    const [Quantite, setQuantite] = useState('');
    const [currentID , setCurrentID] = useState(null);
    const handleOnUpdate = async () => {
        try {
            const response = await axios.patch(process.env.REACT_APP_URL_BASE+`/accessoire/${currentID}`,
                {
                    Nom: Nom,
                    Quantite: Quantite
                }
                ,
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200) {
                handleRefetchDataChange();
                notifySuccess(response.data.message);
                handleClose();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error updating accessory: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error updating accessory:", error.message);
            }
        }
    };
    useEffect(() => { 
        const fetchAccessoryDATA = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_URL_BASE+`/accessoire`,
                {
                    method: "GET",
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                    },
                }
                );
        
                if (response.ok) {
                    const data = await response.json();
                    setAccessoryDATA(data);
                } else {
                    console.error("Error receiving accessoire data:", response.message);
                }
            } catch (error) {
                console.error("Error fetching accessoire data:", error);
            } finally {
                setLoading(true);
            }
        };
        fetchAccessoryDATA();
    },[user?.token, refetchData]);
    
    const columns = [
        {
            name: "nom",
            label: "Nom",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            name: "quantite",
            label: "Quantite",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value ? value : "indéfinie"}
                        </p>
                    )
                }
            }
        },
        {
            label: " ",
            name: "id",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div>
                            <button style={{backgroundColor: '#1988ff'}} onClick={() => handleClickOpen(value) }>
                                Edit
                            </button>
                            <button style={{backgroundColor: '#1988ff'}} onClick={() => Redirection(`/accessoire/${value}`) }>
                                Voir
                            </button>
                            <button style={{backgroundColor: '#DA171B'}} onClick={() => alert('entraîner') }>
                                Entraîner
                            </button>
                        </div>
                    )
                }
            }
        }
    ]; 

    const Redirection = (path) => {
        navigate(`${path}`)
    }

    if (!Loading) {
        return (
          <div className="CircularProgress-app">
            <div className="CircularProgress-container">
              <CircularProgress className='CircularProgress' />
            </div>  
            <h1>Préparation des composants de la page...</h1>
          </div>
        );
    }
    
    return (
        <div className="pages-container">
            <DataTable name={"accessoires"} data={AccessoryDATA} columns={columns} handleClickOpen={handleClickCreateOpen}/>
            <CreateAccessoryDialog  open={openCreate} handleClose={handleClose} user={user} refetchData={handleRefetchDataChange}/>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Modification</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Cette modification sera appliquée directement après confirmation.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="nom"
                    name="nom"
                    label="Entre le nouveau nom de ce accessoire"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setNom(e.target.value)}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="quantite"
                    name="quantite"
                    label="Entre la nouvelle quantité de ce accessoire"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setQuantite(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleOnUpdate}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </div>
    );
}
export default AccessoryDATAPage;