import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuthContext } from './hooks/useAuthContext';
import { CircularProgress } from '@mui/material';
import LoginPage from './Pages/LoginPage';
import NavAsideBAar from './components/NavAsideBAar';
import TVPage from './Pages/TVPage';
import LotPage from './Pages/LotPage';
import TVDetails from './Pages/TVDetails';
import AccessoryDetails from './Pages/AccessoryDetails';
import AccessoryPage from './Pages/AccessoryPage';
import ProfilPage from './Pages/ProfilPage';
import CategoriePage from './Pages/CategoriePage';

function App() {
  const { user } = useAuthContext();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  if (!isOnline) {
    return (
      <div className="CircularProgress-app">
        <div className="CircularProgress-container">
          <CircularProgress className='CircularProgress' />
        </div>  
        <h1>Pas de connexion Internet...</h1>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <main>
        <Routes>
          <Route path="/" element={user ? <NavAsideBAar /> : <LoginPage />} >
            <Route index element={<TVPage />} />
            <Route path="profil" element={<ProfilPage />} />
            <Route path="accessoire" element={<AccessoryPage />} />
            <Route path="categorie" element={<CategoriePage />} />
            <Route path="lot" element={<LotPage />} />
          </Route>
          <Route
            path="/:id"
            element={user ?                 
              <TVDetails/> : <LoginPage />
            }
          />
          <Route
            path="/accessoire/:id"
            element={user ?                 
              <AccessoryDetails/> : <LoginPage />
            }
          />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
