import LoadingButton from '@mui/lab/LoadingButton';

const LoginButton = ({ text, onClick, loading, sx = {} }) => {
    return (
        <LoadingButton
            size="large"
            onClick={onClick}
            loading={loading}
            variant="contained"
            sx={sx}
        >
            {text}
        </LoadingButton>
    );
};

export default LoginButton;
