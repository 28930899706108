import './style.css';
import LOGO_IMG from '../../assets/Logo.png';
import { IoMenu } from "react-icons/io5";
import { AiFillProduct } from "react-icons/ai";
import { GiExitDoor } from "react-icons/gi";
import { FaSitemap } from "react-icons/fa";
import { IoTimer } from "react-icons/io5";
import { useState } from 'react';
import { FaUserCircle } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import CategoryIcon from '@mui/icons-material/Category';


const AsideBar = () => {
    const [active, setActive] = useState(false);
    const { logout } = useLogout();
    const location = useLocation();

    //handle active class
    const handleActive = () => {
        setActive(!active);
    }
    //handle logout
    const submitLogout = () => {
        logout();
    }
    return (
        <div className={`AsideBar-container ${active ? 'active' : ''}`}>
            <div className="AsideBar-top">
                <div className="AsideBar-logo">
                    <img src={LOGO_IMG} alt="logo" />
                </div>
                <IoMenu onClick={handleActive} id='menu-btn'/>
            </div>
            <NavLink to='/profil' className={`AsideBar-item ${
                    location.pathname === "/profil" ? "aside-item-active" : ""}`}>
                <div className="AsideBar-user">
                        <div>
                            <FaUserCircle className='user-img'/>
                        </div>
                    <div className="user-infos">
                        <p className='user-name'>Utilisateur</p>
                        <p>Role</p>
                    </div>
                </div>
            </NavLink>
            <ul>
                <li>
                    <NavLink to='/' className={`AsideBar-item ${
                    location.pathname === "/" ? "aside-item-active" : ""}`}>
                        <AiFillProduct className='AsideBar-icon'/>
                        <span className='side-item'>Produits</span>
                    </NavLink>
                    <span className="tooltip">Produits</span>
                </li>
                <li>
                    <NavLink to='/accessoire' className={`AsideBar-item ${
                    location.pathname === "/accessoire" ? "aside-item-active" : ""}`}>
                        <FaSitemap className='AsideBar-icon'/>
                        <span className='side-item'>Accessoire</span>
                    </NavLink>
                    <span className="tooltip">Accessoire</span>
                </li>
                <li>
                    <NavLink to='/categorie' className={`AsideBar-item ${
                    location.pathname === "/categorie" ? "aside-item-active" : ""}`}>
                        <CategoryIcon className='AsideBar-icon'/>
                        <span className='side-item'>Categorie</span>
                    </NavLink>
                    <span className="tooltip">Categorie</span>
                </li>
                <li>
                    <NavLink to='/lot' className={`AsideBar-item ${
                    location.pathname === "/lot" ? "aside-item-active" : ""}`}>
                        <IoTimer className='AsideBar-icon'/>
                        <span className='side-item'>Planification</span>
                    </NavLink>
                    <span className="tooltip">Planification</span>
                </li>
                <li>
                    <NavLink to='/' className="AsideBar-item" onClick={submitLogout}>
                        <GiExitDoor className='AsideBar-icon'/>
                        <span className='side-item'>Déconnecter</span>
                    </NavLink>
                    <span className="tooltip">Déconnecter</span>
                </li>
            </ul>
        </div>
    );
}
export default AsideBar;