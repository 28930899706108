import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import './css/accessoireDetailsStyle.css';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, ImageList, Typography } from '@mui/material';
import TextFieldComponent from '../components/forms/TextField';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ButtonField from '../components/buttons/ButtonField';
import UploadField from '../components/buttons/UploadField';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgVideo from 'lightgallery/plugins/video';import lgRotate from 'lightgallery/plugins/rotate';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const AccessoryDetails = () => {
    const notifyFailed = (message) => toast.info(message);
    const notifySuccess = (message) => toast.success(message);
    const [AccessoryDetails, setAccessoryDetails] = useState({});
    const { user } = useAuthContext();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [IMGsData, setIMGsData] = useState([]);
    const navigate = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDeleteIMGDialog, setopenDeleteIMGDialog] = useState(false);
    const [CurrentIMGID, setCurrentIMGID] = useState('');
    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };
    const handleClickOpenDeleteIMGDialog = (id) => {
        setCurrentIMGID(id);
        setopenDeleteIMGDialog(true);
    };
    const handleClose = () => {
        setCurrentIMGID("");
        setOpenDeleteDialog(false);
        setopenDeleteIMGDialog(false);
    };
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('files', file); // Append each file individually
        });        
        formData.append('AccessoireID', id);
        handleUpdloadImages(formData);
    };
    const fetchTVIMGData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_BASE}/image/accessoire/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setIMGsData(data);
            } else {
                console.error("Error receiving accessory images data:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching accessory images data:", error);
        } finally {
            setLoading(true);
        }
    };
    useEffect(() => {
        const fetchAccessoryDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_BASE}/accessoire/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setAccessoryDetails(data);
                    fetchTVIMGData();
                } else {
                    console.error("Error receiving accessory data:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching accessory data:", error);
            } finally {
                setLoading(true);
            }
        };
        fetchAccessoryDetails();
    }, [user?.token, id, CurrentIMGID]);

    const handleDeleteSubmit = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_URL_BASE+`/accessoire/${id}`,
              {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200) {
              notifySuccess(response.data.message);
              GoBack();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error deleting accessory: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error deleting accessory:", error.message);
            }
        }
    };
    const handleDeleteIMGSubmit = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_URL_BASE+`/image/delete/${CurrentIMGID}`,
              {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200) {
                setIMGsData(prevData => prevData.filter(item => item.id !== CurrentIMGID));
                notifySuccess(response.data.message);
                handleClose();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error deleting images: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error deleting images:", error.message);
            }
        }
    };
    const handleUpdloadImages = async (formData) => {
        try {
            const response = await axios.post(process.env.REACT_APP_URL_BASE+`/image/upload`,
                formData
                ,
                {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${user?.token}`,
                }
              }
            );
            if (response.status === 200) {
                notifySuccess(response.data.message);
                fetchTVIMGData();
            } else {
              notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error uploading images: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error uploading images:", error.message);
            }
        }
    };
    const GoBack = () => {
        navigate(-1)
    }

    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);

    const progressRef = useRef(() => {});
    useEffect(() => {
        progressRef.current = () => {
        if (progress > 100) {
            setProgress(0);
            setBuffer(10);
        } else {
            const diff = Math.random() * 10;
            const diff2 = Math.random() * 10;
            setProgress(progress + diff);
            setBuffer(progress + diff + diff2);
        }
        };
    });

    useEffect(() => {
        const timer = setInterval(() => {
        progressRef.current();
        }, 500);

        return () => {
        clearInterval(timer);
        };
    }, []);

    if (!loading || !AccessoryDetails.nom) {
        return (
            <div className="CircularProgress-app">
                <div className="CircularProgress-container">
                    <CircularProgress className="CircularProgress" />
                </div>
                <h1>Préparation des composants de la page...</h1>
            </div>
        );
    }

    return (
        <div className="AccessoryDetails-container">
            <div className="AccessoryDetails-content">
                <div className="AccessoryDetails-content-navbar">
                    <div className="AccessoryDetails-content-navbar-title">
                        <ArrowBackIosIcon onClick={GoBack} style={{cursor:'pointer'}}/>
                        <h1>Details du accessoire</h1>
                    </div>
                    <ButtonField 
                        text="Supprimer"
                        onClick={handleClickOpenDeleteDialog}
                        color="#DA171B"
                        backgroundColor="#fff"
                        fontSize="16px"
                    />
                </div>
                <div className="AccessoryDetails-content-navbar-trainingtop-bar">
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                    </Box>
                    <h1>En cours d'entraînement...</h1>
                </div>
                <div className="AccessoryDetails-content-form">
                    <div className="AccessoryDetails-img-content-Statut-bar">
                        <Box sx={{ position: 'relative', display: 'inline-flex', width:"100%" }}>
                            <Box
                                sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div" color="text.secondary">
                                {`${progress}%`}
                                </Typography>
                            </Box>
                            <Stack spacing={2} sx={{ width:"100%" }}>
                                <h1>Statut d'entraînement:</h1>
                                <BorderLinearProgress variant="determinate" value={progress} />
                            </Stack>
                        </Box>                
                    </div>
                    <h1>Details:</h1>
                    <TextFieldComponent
                        type="text"
                        label="Nom"
                        DefaultValue={AccessoryDetails.nom}
                        minLength={0}
                        maxLength={100}
                        obligatory={false}
                        color="#333"
                        readOnly={true}
                        initialHelperText='Nom indéfinie'
                    />
                    <TextFieldComponent
                        type="text"
                        label="Quantite"
                        DefaultValue={AccessoryDetails.quantite}
                        minLength={0}
                        maxLength={100}
                        obligatory={false}
                        color="#333"
                        readOnly={true}
                        initialHelperText='Quantite indéfinie'
                    />
                </div>
            </div>
            <div className="AccessoryDetails-img-content">
                <div className="AccessoryDetails-img-content-title-bar">
                    <h1>Images:</h1>
                    {IMGsData.length > 0 && <UploadField onChange={handleFileChange}/>}
                </div>
                {IMGsData.length > 0 ? (
                    <ImageList variant="masonry" cols={4} gap={10}>
                        <LightGallery
                            speed={1000}
                            plugins={[
                                lgAutoplay,
                                lgRotate,
                                lgThumbnail,
                                lgVideo,
                                lgZoom,
                            ]}
                        >
                            {IMGsData.map((item) => (
                                <a key={item.img} className="ImageListItem" href={`${process.env.REACT_APP_URL_BASE}/files/${item.chemin}?w=248&fit=crop&auto=format`}>
                                    <ClearRoundedIcon className="ImageListItem-DeleteIcon" onClick={()=>handleClickOpenDeleteIMGDialog(item.id)}/>
                                    <img
                                        srcSet={`${process.env.REACT_APP_URL_BASE}/files/${item.chemin}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${process.env.REACT_APP_URL_BASE}/files/${item.chemin}?w=248&fit=crop&auto=format`}
                                        alt={item.chemin}
                                        loading="lazy"
                                    />
                                </a>
                            ))}
                        </LightGallery>
                    </ImageList>
                ) : (
                    <div className="dropzone-file-container">
                        <label htmlFor="dropzone-file" className="dropzone-file-content">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg
                                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">Click to upload</span> or drag and drop
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                            </div>
                            <input id="dropzone-file" multiple onChange={handleFileChange}  type="file" className="hidden" />
                        </label>
                    </div>
                )}
            </div>
            <Dialog
                open={openDeleteDialog}
                onClose={false}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Suppression du accessoire</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Cette suppression sera appliquée directement après la confirmation.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleDeleteSubmit}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteIMGDialog}
                onClose={false}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Suppression d'une image</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Cette suppression sera appliquée directement après la confirmation.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleDeleteIMGSubmit}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </div>
    );
};

export default AccessoryDetails;
