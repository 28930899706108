import { useEffect, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import './css/LotStyle.css';
import { CircularProgress } from '@mui/material';
import DataTable from '../components/tables/DataTable';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CreateLotDialog from '../components/Dialogs/CreateLotDialog';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LotPage = () => {
    const notifyFailed = (message) => toast.info(message);
    const notifySuccess = (message) => toast.success(message);
    const { user } = useAuthContext();
    const [LotData, setLotData] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const handleClose = () => {
        setCurrentID(null);
        setOpenCreate(false);
        setOpenDeleteDialog(false);
    };
    const handleClickCreateOpen = () => {
        setOpenCreate(true);
    };
    //handle delete dialog
    const handleClickDeleteOpen = (id) => {
        setCurrentID(id);
        setOpenDeleteDialog(true);
    }; 
    const [refetchData, setrefetchData] = useState(false);
    const handleRefetchDataChange = () => {
        setrefetchData(!refetchData);
    }
    const [currentID , setCurrentID] = useState(null);
    // handle delete
    const handleOnDelete = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_URL_BASE+`/lot/${currentID}`,
                {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                }
                }
            );
            if (response.status === 200) {
                setLotData(prevData => prevData.filter(item => item.id !== currentID));
                handleRefetchDataChange();
                notifySuccess(response.data.message);
                handleClose();
            } else {
                notifyFailed(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                notifyFailed(error.response.data.message);
            } else if (error.request) {
            // Request was made but no response was received
            console.error("Error deleting lot: No response received", error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error deleting lot:", error.message);
            }
        }
    };
    useEffect(() => { 
        const fetchLotData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_URL_BASE+`/lot`,
                {
                    method: "GET",
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                    },
                }
                );
        
                if (response.ok) {
                    const data = await response.json();
                    setLotData(data);
                } else {
                    console.error("Error receiving Lot data:", response.message);
                }
            } catch (error) {
                console.error("Error fetching Lot data:", error);
            } finally {
                setLoading(true);
            }
        };
        fetchLotData();
    },[user?.token, refetchData]);
    
    const columns = [
        {
            name: "numero",
            label: "Numero",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            name: "startTime",
            label: "StartTime",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {formatDate(value)}
                        </p>
                    )
                }
            }
        },
        {
            name: "endTime",
            label: "EndTime",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {formatDate(value)}
                        </p>
                    )
                }
            }
        },
        {
            name: "taille",
            label: "Taille",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            label: " ",
            name: "id",
            options: {
                customBodyRender: (value) => {
                    return (
                        <button style={{backgroundColor: '#DA171B'}} onClick={() => handleClickDeleteOpen(value) }>
                            Delete
                        </button>
                    )
                }
            }
        },
    ]; 

    if (!Loading) {
        return (
          <div className="CircularProgress-app">
            <div className="CircularProgress-container">
              <CircularProgress className='CircularProgress' />
            </div>  
            <h1>Préparation des composants de la page...</h1>
          </div>
        );
    }
    
    return (
        <div className="pages-container">
            <DataTable name={"lots"} data={LotData} columns={columns} handleClickOpen={handleClickCreateOpen}/>
            <CreateLotDialog  open={openCreate} handleClose={handleClose} user={user} refetchData={handleRefetchDataChange}/>
            <Dialog
                open={openDeleteDialog}
                onClose={false}
                PaperProps={{
                    component: 'form',
                }}
            >
                <DialogTitle>Suppression</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Cette suppression sera appliquée directement après confirmation.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleOnDelete}>confirmer</Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </div>
    );
}
const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const monthNames = [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${month} ${day}, ${year} at ${hours}:${formattedMinutes}`;
};
export default LotPage;