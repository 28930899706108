import { useEffect, useState } from 'react';
import './css/HomeStyle.css';
import { useAuthContext } from "../hooks/useAuthContext";
import { CircularProgress } from '@mui/material';
import DataTable from '../components/tables/DataTable';
import { useNavigate } from 'react-router-dom';
import CreateTvDialog from '../components/Dialogs/CreateTvDialog';
import UpdateTvDialog from '../components/Dialogs/UpdateTvDialog';
import { ToastContainer, toast } from 'react-toastify';

const TVPage = () => {
    const [TVData, setTVData] = useState([]);
    const { user } = useAuthContext();
    const [Loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refetchData, setrefetchData] = useState(false);
    const handleRefetchDataChange = () => {
        setrefetchData(!refetchData);
    }
    const [open, setOpen] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [currentID , setCurrentID] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpenUpdateDialog = (id) => {
        setCurrentID(id);
        setOpenUpdateDialog(true);
    };
    const handleClose = () => {
        setCurrentID(null);
        setOpen(false);
        setOpenUpdateDialog(false);
    };
    
    useEffect(() => { 
        const fetchTVData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_URL_BASE+`/televiseur`,
                {
                    method: "GET",
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                    },
                }
                );
        
                if (response.ok) {
                    const data = await response.json();
                    setTVData(data);
                } else {
                    console.error("Error receiving TV data:", response.message);
                }
            } catch (error) {
                console.error("Error fetching TV data:", error);
            } finally {
                setLoading(true);
            }
        };
        fetchTVData();
    },[user?.token, refetchData]);
    
    const columns = [
        {
            name: "modele",
            label: "Modele",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            name: "marque",
            label: "Marque",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            name: "categorie",
            label: "Categorie",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            name: "lot",
            label: "Lot",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            name: "taille",
            label: "Taille",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            name: "resolution",
            label: "Resolution",
            options: {
                customBodyRender: (value) => {
                    return (
                        <p>
                            {value}
                        </p>
                    )
                }
            }
        },
        {
            label: " ",
            name: "id",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div>
                            <button style={{backgroundColor: '#1988ff'}} onClick={() => handleClickOpenUpdateDialog(value) }>
                                Edit
                            </button>
                            <button style={{backgroundColor: '#1988ff'}} onClick={() => Redirection(`/${value}`) }>
                                Voir
                            </button>
                            <button style={{backgroundColor: '#DA171B'}} onClick={() => alert('entraîner') }>
                                Entraîner
                            </button>
                        </div>
                    )
                }
            }
        },
    ]; 

    const Redirection = (path) => {
        navigate(`${path}`)
    }

    if (!Loading) {
        return (
          <div className="CircularProgress-app">
            <div className="CircularProgress-container">
              <CircularProgress className='CircularProgress' />
            </div>  
            <h1>Préparation des composants de la page...</h1>
          </div>
        );
    }

    return (
        <div className="pages-container">
            <DataTable name={"televiseurs"} data={TVData} columns={columns} handleClickOpen={handleClickOpen}/>
            <CreateTvDialog  open={open} handleClose={handleClose} user={user} refetchData={handleRefetchDataChange}/>
            <UpdateTvDialog  open={openUpdateDialog} handleClose={handleClose} id={currentID} user={user} refetchData={handleRefetchDataChange}/>
            <ToastContainer/>
        </div>
    );
}
export default TVPage;